import React from 'react';
import { Grid, Typography } from '@mui/material';

const Greeting = ({ customer_name }) => (
    <Grid container item xs={12}>
        <Typography variant="h2" gutterBottom color="gray">
            Hej {customer_name}
        </Typography>
    </Grid>
);

export default Greeting;
