import React from 'react';
import { Box, Typography, Link as MuiLink, Divider } from '@mui/material';
import ReactMarkdown from 'react-markdown';

function LinkRenderer({ href, children }) {
    return (
        <MuiLink href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </MuiLink>
    );
}

const PromptResult = ({ response, price, source_url }) => {
    return (
        <Box>
            <Box>
                <Box overflow="auto" maxHeight="15vh">
                    <Typography variant="body1">
                        <ReactMarkdown
                            components={{
                                a: LinkRenderer,
                                // other elements can be customized here as needed
                            }}
                        >
                            {response}
                        </ReactMarkdown>
                    </Typography>
                </Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Typography variant="body2">{"Source: "}
                    <MuiLink href={source_url} target="_blank" rel="noopener noreferrer">
                        {source_url}
                    </MuiLink>
                </Typography>
                <Typography variant="body2">Kostnad (sek): {price}</Typography>
            </Box>
        </Box>
    );
};

export default PromptResult;
