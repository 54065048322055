
function Error() {

  return (
    <div className="Error">
      <p> 404...</p>
    </div>
  );
}

export default Error;
