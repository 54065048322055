import React from 'react';
import { Typography, List, ListItemButton, ListItemText } from '@mui/material';

const Instructions = ({setExampleQuestion, customerInfo}) => {  

  const handleCopy = (text) => {
    setExampleQuestion(text)
  };


  return (
    <div>
      <Typography variant="body1" gutterBottom>
        {customerInfo.customer_instructions}
      </Typography>
      <List dense disablePadding sx={{ color: "gray" }}>
        {customerInfo.sample_questions.map((question, index) => (
          <ListItemButton key={index} onClick={() => handleCopy(question)}>
            <ListItemText primary={question} />
          </ListItemButton>
        ))}
      </List>
    </div>
  );
};

export default Instructions;
