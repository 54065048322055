import { Box, Button, Tooltip } from '@mui/material';

const QuestionLink = ({ baseUrl, latestQuestion }) => {
    // Encode the question to ensure it's safe to include in a URL
    const encodedQuestion = encodeURIComponent(latestQuestion);

    return (
        <Box>
            <Tooltip title="Titta vad ni får för resultat på er hemsida">
                <Button
                    variant="outlined" // or "outlined" or "text" depending on your preference
                    color="primary"     // or other colors like "secondary"
                    href={`${baseUrl}${encodedQuestion}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textTransform: 'none' }} // keeps the button text as is, without uppercasing
                    fullWidth
                >
                    {latestQuestion}
                </Button>
            </Tooltip>
        </Box>
    );
};

export default QuestionLink;
