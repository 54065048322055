import React, { useState } from 'react';
import { Box, Button, CircularProgress, LinearProgress, Container, Divider, Typography, Paper, ThemeProvider } from '@mui/material';
import QuestionForm from './QuestionForm';
import PromptResult from './PromptResult';
import LinkResults from './LinkResults';
import { unstable_createMuiStrictModeTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = unstable_createMuiStrictModeTheme();
theme = responsiveFontSizes(theme);

function Lund() {
  const [loading, setLoading] = useState(false);
  const [urlSearchLoading, setUrlSearchLoading] = useState(false);
  const [formInput, setFormInput] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null)
  const [urlErrorMessage, setUrlErrorMessage] = useState(null)
  const [urlResults, setUrlResults] = useState(null);
  const [promptResult, setPromptResult] = useState({
    completion_tokens: 0,
    prompt_tokens: 0,
    price: 0.0,
    generated_response: '',
    source_url: ''
  });

  const handleFormSubmit = async (formData) => {
    setLoading(true);
    setUrlSearchLoading(true);
    setShowForm(false);
    setFormInput(formData);
    try {
      // Create both promises
      const aiRequest = fetch('https://api.redbar.tech/ai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          input: formData.question,
          model: formData.model,
          chunking_strategy: formData.strategy,
          customer_name: "Lund municipality"
        })
      });

      const vsearchRequest = fetch(`https://api.redbar.tech/vsearch/${encodeURIComponent(formData.question)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // Run both promises in parallel
      await Promise.all([
        aiRequest.then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            if (response.status === 400) {
              setErrorMessage(errorData.detail);
            } else {
              throw new Error(`Error ${response.status}: ${errorData.detail}`);
            }
            return;
          }
          const result = await response.json();
          setLoading(false);
          setPromptResult(result); // Set state directly within the promise handler
        }).catch(error => {
          // Handle and set error message for the AI request
          setErrorMessage(error.message);
        }),

        vsearchRequest.then(async (response) => {
          console.log("running vsearch")
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`VSearch Request Error ${response.status}: ${errorData.detail}`);
          }
          const result = await response.json();
          setUrlResults(result); // Set state directly within the promise handler
          setUrlSearchLoading(false);
        }).catch(error => {
          // Handle and set error message for the vsearch request
          setUrlErrorMessage(error.message);
        })
      ]);

    } catch (error) {
      console.error('Error:', error);
      // If not caught in individual catch, handle global errors
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
      setUrlSearchLoading(false);
    }
  };


  const handleReturn = () => {
    setFormInput(null);
    setErrorMessage(null);
    setLoading(false);
    setUrlSearchLoading(false);
    setShowForm(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container className="LundD" maxWidth="sm">
        <Box height="100dvh" display="flex" flexDirection="column">
          <Box mb={2}>
            <Typography variant="h2" mt={2} >Demo</Typography>
            <Divider />
            {formInput && (
              <Box mt={2}>
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                  <Typography variant="body2" sx={{ mr: 2 }}>{"Input:"}</Typography>
                  <Typography variant="h4" gutterBottom>{formInput.question}</Typography>
                </Box>
                <Box display="flex" justifyContent="flex-start" alignItems="center" ml={"16px"}>
                  <Typography variant="body2" >{formInput.model}</Typography>
                  <Divider sx={{ mr: 2, ml: 2 }} orientation="vertical" flexItem />
                  <Typography variant="body2" >{formInput.strategy}</Typography>
                </Box>
              </Box>
            )}
          </Box>
          {showForm && !loading && (
            <Box flex="1" display="flex">
              <QuestionForm onSubmit={handleFormSubmit} />
            </Box>)}
          {formInput && (
            <Box flex="1" display="flex">
              <Box flex="1" display="flex" flexDirection="column" justifyContent="space-between">
                <Box mb={2} >
                  <Paper sx={{p: 2}}>

                    {loading ? (
                      <Box display="flex" justifyContent="center" alignItems="center" padding={2} height="15vh">
                        <CircularProgress />
                      </Box>
                    ) : !errorMessage && promptResult ? (
                      <PromptResult response={promptResult.generated_response} price={promptResult.price} source_url={promptResult.source_url} />
                    ) : errorMessage}

                  </Paper>
                </Box>
                <Box id="url-search-results" overflow="auto" flex={1} display="flex" mb={2}>
                  {urlSearchLoading ? (
                    <Box padding={2} width="100%">
                      <LinearProgress />
                    </Box>
                  ) : !urlErrorMessage && urlResults ? (
                    <Box>
                      <Typography variant="h6">Sökresultat</Typography>
                      <LinkResults urlResults={urlResults} />
                    </Box>
                  ) : urlErrorMessage}
                </Box>
                <Box mb={2}>
                  <Button variant="contained" onClick={handleReturn} fullWidth>Tillbaka</Button>
                </Box>
              </Box>
            </Box>)}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Lund;
