// Intro.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Chip } from '@mui/material';

import Greeting from './Greeting';
import Instructions from './Instructions';
import QuestionInput from './QuestionInput';
import LatestQuestion from './LatestQuestion';
import AIResponse from './AIResponse';
import VectorSearchResponse from './VectorSearchResponse';
import Footer from './Footer'
import GeneralError from '../GeneralError/GeneralError';

const genericDemo = "Det här är en demo för att visa hur AI kan hjälpa kommunens medborgare att få mer relevanta svar på sina frågor. AI:n använder sig av information från er hemsida för att generera svaren. Testa gärna olika typer av frågor. Här är några exempel:"

// DATABASE PLEASE
const validCustomers = {
    "hassleholm": { "name": "Hässleholm", "search_url": "https://www.hassleholm.se/sok/sokresultat?query=", "sample_questions": ["Vem bestämmer egentligen?", "Hur kan jag söka skola?", "Vad är det bästa med Hässleholm?"], "customer_instructions": genericDemo },
    "eslov": { "name": "Eslöv", "search_url": "https://eslov.se/?type=page&s=", "sample_questions": ["Vem bestämmer i kommunen?", "Hur kan jag påverka min kommun?", "Vad gör jag om mitt barn mobbas i skolan?"], "customer_instructions": genericDemo },
    "hoganas": { "name": "Höganäs", "search_url": "https://www.hoganas.se/ovrigt/sok.html?query=", "sample_questions": ["Hur får jag jobb i kommunen?", "Hur kan jag påverka kommunen?", "Vad ska jag göra om jag hittar en död säl på stranden?"], "customer_instructions": genericDemo },
    "alvesta": { "name": "Alvesta", "search_url": "https://www.alvesta.se/?s=", "sample_questions": ["Varför ska jag flytta till Alvesta?", "Hur kan jag söka skola?", "Hur kontaktar jag er?"], "customer_instructions": genericDemo },
};

const Intro = () => {
    const { customer_name } = useParams();
    const [latestQuestion, setLatestQuestion] = useState('');
    const [aiResponse, setAiResponse] = useState(''); // Initial state as an array of empty objects
    const [vectorSearchResponse, setVectorSearchResponse] = useState([]); // Initial state as an array of empty objects
    const [exampleQuestion, setExampleQuestion] = useState('')
    const [customerInfo, setCustomerInfo] = useState({ "name": "", "search_url": "", "sample_questions": [], "customer_instructions": "" })

    useEffect(() => {
        if (validCustomers.hasOwnProperty(customer_name)) {
            setCustomerInfo(validCustomers[customer_name]);
        }
    }, [customer_name]);

    if (!validCustomers.hasOwnProperty(customer_name)) {
        return <GeneralError />;
        // Alternatively, you can use navigate to redirect:
        // navigate('/general-error');
        // return null; // Return null after redirect
    }

    return (
        <Box
            sx={{
                minHeight: '100dvh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                m: 0,
                width: '100%',
                p: 2,
                boxSizing: 'border-box'
            }}
        >
            <Box sx={{ width: "100%", maxWidth: 512, }}>
                <Greeting customer_name={customerInfo.name} />
                <Instructions setExampleQuestion={setExampleQuestion} customerInfo={customerInfo} />
                <Divider sx={{ width: '100%', my: 2 }} />
                <QuestionInput setLatestQuestion={setLatestQuestion} setAiResponse={setAiResponse} setVectorSearchResponse={setVectorSearchResponse} exampleQuestion={exampleQuestion} customerName={customer_name} />
                <LatestQuestion latestQuestion={latestQuestion} baseUrl={customerInfo.search_url} />
                <>
                    {aiResponse && (
                        <>
                            <AIResponse response={aiResponse} />
                            <Divider sx={{ width: '100%', marginTop: 4, marginBottom: 2 }} textAlign="left">
                                <Chip label="Sökresultat och referenser" size="small" />
                            </Divider>
                            {vectorSearchResponse.length > 0 && <VectorSearchResponse results={vectorSearchResponse} />}
                        </>
                    )}
                </>
            </Box>
            <Box sx={{ width: "100%", pb: 2 }}>
                <Divider sx={{ width: '100%', my: 2 }} />
                <Footer />
            </Box>
        </Box>
    );
};

export default Intro;
