import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Typography, FormHelperText } from '@mui/material';

const QuestionForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    question: '',
    model: '',
    strategy: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};

    if (formData.question.trim() === '') {
      errors.question = 'Ställ din fråga är obligatorisk.';
    } else if (formData.question.trim().length > 50) {
      errors.question = 'Frågan får inte vara längre än 50 tecken.';
    }

    if (formData.model === '') {
      errors.model = 'Välj en modell.';
    }

    if (formData.strategy === '') {
      errors.strategy = 'Välj en strategi.';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // Pass form data to the parent component or handle it here
    onSubmit(formData);

    // Reset form fields
    setFormData({
      question: '',
      model: '',
      strategy: '',
    });

    // Clear errors
    setErrors({});
  };

  return (
    <Box className="Lund-form" component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" width="100%">
      <FormControl variant="standard" fullWidth style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box mb={2}>
          <TextField
            id="question"
            name="question"
            variant="outlined"
            label="Ställ din fråga"
            required
            fullWidth
            value={formData.question}
            onChange={handleChange}
            error={!!errors.question}
            helperText={errors.question}
          />
        </Box>
        <Box flexGrow={1}>
          <Box mb={2} >
          <FormLabel component="legend">Modell</FormLabel>
          <FormHelperText>Välj vilken modell som ska användas. GPT4-modellerna är lite dyrare.</FormHelperText>
            <RadioGroup
              row
              name="model"
              value={formData.model}
              onChange={handleChange}

            >
              <FormControlLabel value="gpt-4o" control={<Radio />} label="GPT-4o" />
              <FormControlLabel value="gpt-4-turbo" control={<Radio />} label="GPT-4 turbo" />
              <FormControlLabel value="gpt-3.5-turbo" control={<Radio />} label="GPT-3.5 turbo" />
            </RadioGroup>
            {errors.model && <Typography color="error">{errors.model}</Typography>}
          </Box>
          <Box>
            <FormLabel component="legend">Strategi</FormLabel>
            <FormHelperText>
            Det finns många sätt att "vektorisera" en hemsida:
            <br />
            - Textlängd: Bra och billigt för väldigt specifik information, kan vara svårt att svara på frågor som kräver mycket kontext.
            <br/>
            - Separerade headers: En mellanväg där man använder "header"-element för att dela upp kontext.
            <br/>
            - Fulla dokument: Hela den relevanta sidan skickas med som kontext till modellen. Dyraste alternativet.
                   
            </FormHelperText>
            <RadioGroup
              row
              name="strategy"
              value={formData.strategy}
              onChange={handleChange}
            >
              <FormControlLabel value="character" control={<Radio />} label="Textlängd" />
              <FormControlLabel value="markdown" control={<Radio />} label="Separerade headers" />
              <FormControlLabel value="full" control={<Radio />} label="Fulla dokument" />
            </RadioGroup>
            {errors.strategy && <Typography color="error">{errors.strategy}</Typography>}
          </Box>
        </Box>
        <Box mb={2}>
          <Button type="submit" variant="contained" fullWidth>Skicka</Button>
        </Box>
      </FormControl>
    </Box>
  );
};

export default QuestionForm;
