import React from 'react';
import { Card, CardContent, Typography, Link, Grid } from '@mui/material';

const VectorSearchResponse = ({ results }) => {
  const cleanTitle = (title) => {
    return title ? title.replace(/ - Eslövs kommun$/, '') : 'Untitled';
  };
  return (

      <Grid container spacing={2}>
        {results.map((result, index) => (
          <Grid item xs={12} key={index}>
            <Card variant="outlined">
              <CardContent>
                {result.url ? (
                  <Link href={result.url} target="_blank" rel="noopener noreferrer" color="inherit" underline="hover">
                    <Typography variant="h8" component="div" gutterBottom>
                      {cleanTitle(result.page_title || "Untitled")}
                    </Typography>
                  </Link>
                ) : (
                  <Typography variant="h8" component="div" gutterBottom>
                    {cleanTitle(result.page_title || "Untitled")}
                  </Typography>
                )}
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {result.caption || "No description available."}
                </Typography>
                {result.url && (
                  <Typography variant="body2" color="textPrimary">
                    <Link href={result.url} target="_blank" rel="noopener noreferrer" color="primary">
                      {result.url}
                    </Link>
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

  );
};

export default VectorSearchResponse;
