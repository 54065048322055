import './Home.css';

function Home() {
  const handleContactClick = () => {
    window.location.href = 'mailto:hej@redbar.tech';
  };
  return (
    <div className="Home">
      <div className="Home-container">
        <div>
          <header className="Home-header">
            <p>redbar.tech</p>
          </header>
          <div className="Home-body">
            <div>
              <p> Redbar Tech är ett nytt konsultföretag som är grymma på AI, data och integration. </p>
              <div className="Button-container">
                <button className="Button" onClick={handleContactClick}>Ta kontakt!</button>
              </div>
            </div>
          </div>
        </div>
        <footer className="Home-footer">
          <div>
            <p>hej@redbar.tech</p>
          </div>
          <div>
            <p>559480-0509</p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Home;
