// LatestQuestion.jsx
import React from 'react';
import { Grid, Typography } from '@mui/material';
import QuestionLink from './QuestionLink';

const LatestQuestion = ({ latestQuestion, baseUrl }) => (
    <Grid container marginTop={2} justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Grid item xs={4}>
            <Typography variant="body1" size="small" color="gray">
                Din senaste fråga:
            </Typography>
        </Grid>
        {latestQuestion ? 
        <Grid item xs={8}>
            <QuestionLink latestQuestion={latestQuestion} baseUrl={baseUrl} />
        </Grid> : null }
    </Grid>
);

export default LatestQuestion;
