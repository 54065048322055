import React from 'react';
import { List, ListItem, ListItemText, Link } from '@mui/material';

const LinkResults = ({ urlResults }) => {
  return (    
    <List sx={{pt: 0}} >
      {urlResults.map((link, index) => (
        <ListItem key={index} sx={{pt: 0, pb: 0}} >
            <ListItemText primary={link.page_title} secondary={
              <Link href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</Link>}/>
        </ListItem>
      ))}
    </List>
  );
};

export default LinkResults;
