import React from 'react';
import { Grid, Paper, IconButton, Box, Tooltip, ClickAwayListener } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TypingText from './TypingText';

const AIResponse = ({ response, references }) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    return (
        <Grid container>
            <Grid item xs={12} >
                <Paper elevation={3} style={{ padding: '16px' }}>
                    <Grid container>
                        <Grid item xs={11}>
                            <TypingText text={response} speed={10} />
                        </Grid>
                        <Grid item xs={1} style={{ position: 'relative' }} >
                            <ClickAwayListener onClickAway={handleTooltipClose} >
                                <Box style={{ position: 'absolute', top: 0, right: 0 }}>
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        onClose={handleTooltipClose}
                                        open={open}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title="Nyfiken hur det fungerar? Ta kontakt: hej@redbar.tech"
                                    >
                                        <IconButton
                                            style={{ padding: 0 }}
                                            aria-label="help"
                                            onClick={handleTooltipOpen}
                                        >
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </ClickAwayListener>
                        </Grid>
                    </Grid>    
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AIResponse;
