import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';

const TypingText = ({ text, speed = 100 }) => {
    const [displayedText, setDisplayedText] = useState('');
    const indexRef = useRef(0);
    const timerRef = useRef(null);

    useEffect(() => {
        // Reset everything when text or speed changes
        setDisplayedText('');
        indexRef.current = 0;
        if (text) {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }

            const timer = setInterval(() => {
                indexRef.current += 1;
                setDisplayedText((prev) => prev + text[indexRef.current - 1]);

                if (indexRef.current >= text.length) {
                    clearInterval(timer);
                }
            }, speed);

            timerRef.current = timer;

            return () => {
                if (timerRef.current) {
                    clearInterval(timerRef.current);
                }
            };
        }
    }, [text, speed]);

    return (

            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: displayedText }} />

    );
};

export default TypingText;
